import axios from "axios"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import BasicTableComponent, { NumberRangeColumnFilter, SelectColumnFilter } from "../../components/basic.table"
import { getToken } from "../../user/User"

const CharacterList = ({filter}) => {
    const columns =  [{
        Header: "Minecraft Name",
        accessor: "player_name"
    }, {
        Header: "Name",
        accessor: "name"
    }, {
        Header: "Geschlecht",
        accessor: "geschlecht",
        Filter: SelectColumnFilter,
        filter: 'includes',
    }, {
        Header: "Alter",
        accessor: "alter",
        Filter: SelectColumnFilter,
        filter: 'includes',
    }, {
        Header: "Aussehen",
        accessor: "aussehen"
    },{
        Header: "Erstellt am",
        accessor: (row, _) => {
            return new Date(row.created * 1000).toLocaleDateString("de-DE", { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }) + " Uhr"
        }
    },{
        Header: "Sekunden bis zur Löschung",
        accessor: "delete_in",
        Filter: NumberRangeColumnFilter,
        filter: 'between'
    },{
        Header: "Wird gelöscht am",
        accessor: (row, _) => {
            return new Date((Math.floor(Date.now() / 1000) + parseInt(row.delete_in)) * 1000).toLocaleDateString("de-DE", { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }) + " Uhr"
        }
    }]

    const [characters, setCharacters] = useState([])
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/api/characters" + (filter === undefined ? "" : "/" + filter), {
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        })
        .then(res => {
            if(res.data === null || res.data === "") {
              return
            }
            setCharacters(res.data)
        }).catch(err => {
            console.error(err)
            toast.error("Charaktere konnten nicht geladen werden.")
        })
    }, [filter])

    return (
        <div>
            <BasicTableComponent columns={columns} data={characters} editUrlPart="character" />
        </div>
    )
}
   
export default CharacterList