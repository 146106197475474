import axios from "axios"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { getToken, useCurrentUser } from "../user/User"
import { Chart, registerables } from 'chart.js'
import { Bar, Pie } from 'react-chartjs-2'
import { toast } from "react-toastify"
import { useTime } from "../time/Time"

Chart.register(...registerables)

const Dashboard = () => {
  function filter(list, propertyKey, propertyValue) {
    return list.filter(entry => entry[propertyKey] === propertyValue)
  }

  function sum(list, propertyKey) {
    var sum = 0
    list.forEach(item => {
      sum += item[propertyKey]
    })
    return sum
  }

  function getRandomColor() {
    var letters = '0123456789ABCDEF'
    var color = '#'
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }

  function sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
  }

  const [openTickets, setOpenTickets] = useState([])
  const [newTickets, setNewTickets] = useState([])
  const [newWarns, setNewWarns] = useState([])
  const [newFactions, setNewFactions] = useState([])
  const [newParticipants, setNewParticipants] = useState([])
  const [moneyMovements, setMoneyMovements] = useState([])
  const [moneyMovementLabels, setMoneyMovementLabels] = useState([])

  const [ticketCategoryChart, setTicketCategoryChart] = useState(null)
  const [clientChart, setClientChart] = useState(null)
  const [punishmentCategoryChart, setPunishmentCategoryChart] = useState(null)
  const [factionRankChart, setFactionRankChart] = useState(null)
  const [incomingMoneyCauseChart, setIncomingMoneyCauseChart] = useState(null)
  const [outgoingMoneyCauseChart, setOutgoingMoneyCauseChart] = useState(null)
  const [playerRankChart, setPlayerRankChart] = useState(null)
  const [characterGenderChart, setCharacterGenderChart] = useState(null)
  const [characterAgeChart, setCharacterAgeChart] = useState(null)
  
  const [onlinetime, setOnlinetime] = useState(0)
  const [money, setMoney] = useState(0)
  const [factions, setFactions] = useState(0)
  const [players, setPlayers] = useState(0)
  const { time } = useTime(onlinetime, 1)
  const [playersAboutToDelete, setPlayersAboutToDelete] = useState(0)
  const [averagePlayersPerFaction, setAveragePlayersPerFaction] = useState(0)
  const [averageIpScore, setAverageIpScore] = useState(0)

  const { uuid } = useCurrentUser()
  if(uuid != null) {
    useEffect(() => {
      const abortCont = new AbortController()

      axios.get(process.env.REACT_APP_API_URL + "/api/tickets", {
          signal: abortCont.signal,
          headers: {
              "Authorization": "Bearer " + getToken()
          }
      })
      .then(res => {
        if(res.data === null || res.data === "") {
          return
        }
        const data = res.data
        setOpenTickets(filter(data, "state", "open"))
        let newData = []
        for(let i = 30; i >= 0; i--) {
          let date = new Date(new Date().getTime() - (i * 86400000))
          newData.push({
            label: date.toLocaleDateString("de-DE", { month: 'numeric', day: 'numeric'}),
            data: [data.filter(ticket => sameDay(new Date(ticket.created * 1000), date)).length],
            backgroundColor: [getRandomColor()],
          })
        }
        setNewTickets(newData)
        
        let categoryLabels = [...new Set(data.map(ticket => ticket.category))]
        let categoryData = []
        let categoryColors = []
        categoryLabels.forEach((category) => {
          categoryData.push(filter(data, "category", category).length)
          categoryColors.push(getRandomColor())
        })
        setTicketCategoryChart(<Pie data={{
          labels: categoryLabels,
          datasets: [{
            data: categoryData,
            backgroundColor: categoryColors,
          }]
        }}/>)
      }).catch(err => {
        if(err.message === "canceled") {
          return
        }
        console.error(err)
        toast.error("Tickets konnten nicht geladen werden.")
      })
        
      axios.get(process.env.REACT_APP_API_URL + "/api/clients", {
          signal: abortCont.signal,
          headers: {
              "Authorization": "Bearer " + getToken()
          }
      })
      .then(res => {
          if(res.data === null || res.data === "") {
            return
          }
          const data = res.data
          let clientLabels = [...new Set(data.map(client => client.client))]
          let clientData = []
          let clientColors = []
          clientLabels.forEach((client) => {
            clientData.push(filter(data, "client", client).length)
            clientColors.push(getRandomColor())
          })
          setClientChart(<Pie data={{
            labels: clientLabels,
            datasets: [{
              data: clientData,
              backgroundColor: clientColors,
            }]
          }}/>)
      }).catch(err => {
        if(err.message === "canceled") {
          return
        }
        console.error(err)
        toast.error("Clients konnten nicht geladen werden.")
      })

      axios.get(process.env.REACT_APP_API_URL + "/api/punishments", {
          signal: abortCont.signal,
          headers: {
              "Authorization": "Bearer " + getToken()
          }
      }).then(res => {
        if(res.data === null || res.data === "") {
          return
        }
        const data = res.data
        let newData = []
        for(let i = 30; i >= 0; i--) {
          let date = new Date(new Date().getTime() - (i * 86400000))
          newData.push({
            label: date.toLocaleDateString("de-DE", { month: 'numeric', day: 'numeric'}),
            data: [data.filter(punishment => sameDay(new Date(punishment.created * 1000), date)).length],
            backgroundColor: [getRandomColor()],
          })
        }
        setNewWarns(newData)

        let categoryLabels = [...new Set(data.map(punishment => punishment.type))]
        let categoryData = []
        let categoryColors = []
        categoryLabels.forEach((type) => {
          categoryData.push(filter(data, "type", type).length)
          categoryColors.push(getRandomColor())
        })
        setPunishmentCategoryChart(<Pie data={{
          labels: categoryLabels,
          datasets: [{
            data: categoryData,
            backgroundColor: categoryColors,
          }]
        }}/>)
      }).catch(err => {
        if(err.message === "canceled") {
          return
        }
        console.error(err)
        toast.error("Verwarnungen konnten nicht geladen werden.")
      })

      axios.get(process.env.REACT_APP_API_URL + "/api/onlinetime", {
          signal: abortCont.signal,
          headers: {
              "Authorization": "Bearer " + getToken()
          }
      }).then(res => {
        if(res.data === null || res.data === "") {
          return
        }
        const data = res.data
        let onlinetimes = [...new Set(data.map(onlinetime => onlinetime.onlinetime))]
        let overallTime = 0
        onlinetimes.forEach((onlinetime) => {
          overallTime = (overallTime + onlinetime) / 2
        })
        setOnlinetime(parseInt(overallTime, 10))
      }).catch(err => {
        if(err.message === "canceled") {
          return
        }
        console.error(err)
        toast.error("Spielzeit konnte nicht geladen werden.")
      })

      axios.get(process.env.REACT_APP_API_URL + "/api/participants", {
          signal: abortCont.signal,
          headers: {
              "Authorization": "Bearer " + getToken()
          }
      }).then(res => {
        if(res.data === null || res.data === "") {
          return
        }
        const data = res.data

        setPlayers(data.length)

        let newData = []
        for(let i = 30; i >= 0; i--) {
          let date = new Date(new Date().getTime() - (i * 86400000))
          newData.push({
            label: date.toLocaleDateString("de-DE", { month: 'numeric', day: 'numeric'}),
            data: [data.filter(participant => sameDay(new Date(participant.created), date)).length],
            backgroundColor: [getRandomColor()],
          })
        }
        setNewParticipants(newData)
        
        let rankLabels = [...new Set(data.map(participant => participant.rank))]
        let rankData = []
        let rankColors = []
        rankLabels.forEach((rank) => {
          rankData.push(filter(data, "rank", rank).length)
          rankColors.push(getRandomColor())
        })
        setPlayerRankChart(<Pie data={{
          labels: rankLabels,
          datasets: [{
            data: rankData,
            backgroundColor: rankColors,
          }]
        }}/>)

        axios.get(process.env.REACT_APP_API_URL + "/api/factions", {
          signal: abortCont.signal,
          headers: {
              "Authorization": "Bearer " + getToken()
          }
        }).then(res => {
          if(res.data === null || res.data === "") {
            return
          }
          const data = res.data
          
          setFactions(data.length)

          let players = 0
          if(data.length > 0) {
            data.forEach((faction) => {
              players += faction.member_count
            })
            players = Number(players / data.length).toFixed(2)
          }
          setAveragePlayersPerFaction(players)

          let newData = []
          for(let i = 30; i >= 0; i--) {
            let date = new Date(new Date().getTime() - (i * 86400000))
            newData.push({
              label: date.toLocaleDateString("de-DE", { month: 'numeric', day: 'numeric'}),
              data: [data.filter(faction => sameDay(new Date(faction.creation_date_timestamp), date)).length],
              backgroundColor: [getRandomColor()],
            })
          }
          setNewFactions(newData)

          let categoryLabels = [...new Set(data.map(faction => faction.rank_name))]
          let categoryData = []
          let categoryColors = []
          categoryLabels.forEach((rank_name) => {
            categoryData.push(filter(data, "rank_name", rank_name).length)
            categoryColors.push(getRandomColor())
          })
          setFactionRankChart(<Pie data={{
            labels: categoryLabels,
            datasets: [{
              data: categoryData,
              backgroundColor: categoryColors,
            }]
          }}/>)
        }).catch(err => {
          if(err.message === "canceled") {
            return
          }
          console.error(err)
          toast.error("Gefolge konnten nicht geladen werden.")
        })

        axios.get(process.env.REACT_APP_API_URL + "/api/characters", {
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        })
        .then(res => {
            if(res.data === null || res.data === "") {
              return
            }

            let data = res.data

            setPlayersAboutToDelete(data.filter(char => char.delete_in <= 604800).length)

            let genderLabels = [...new Set(data.map(character => character.geschlecht))]
            let genderData = []
            let genderColors = []
            genderLabels.forEach((geschlecht) => {
              genderData.push(filter(data, "geschlecht", geschlecht).length)
              genderColors.push(getRandomColor())
            })
            setCharacterGenderChart(<Pie data={{
              labels: genderLabels,
              datasets: [{
                data: genderData,
                backgroundColor: genderColors,
              }]
            }}/>)

            let ageLabels = [...new Set(data.map(character => character.alter))]
            let ageData = []
            let ageColors = []
            ageLabels.forEach((alter) => {
              ageData.push(filter(data, "alter", alter).length)
              ageColors.push(getRandomColor())
            })
            setCharacterAgeChart(<Pie data={{
              labels: ageLabels,
              datasets: [{
                data: ageData,
                backgroundColor: ageColors,
              }]
            }}/>)
        }).catch(err => {
          console.error(err)
          toast.error("Charaktere konnten nicht geladen werden.")
        })

      }).catch(err => {
        if(err.message === "canceled") {
          return
        }
        console.error(err)
        toast.error("Spieler konnten nicht geladen werden.")
      })

      axios.get(process.env.REACT_APP_API_URL + "/api/addresses", {
        headers: {
            "Authorization": "Bearer " + getToken()
        }
      })
      .then(res => {
        if(res.data === null || res.data === "") {
          return
        }

        let overallScore = 0
        res.data.forEach((ip) => {
          overallScore += ip.score
        })
        setAverageIpScore(Number(overallScore / res.data.length).toFixed(0))
      }).catch(err => {
        if(err.message === "canceled") {
          return
        }
        toast.error("IP Adressen konnten nicht geladen werden.")
      })

      axios.get(process.env.REACT_APP_API_URL + "/api/moneymovements", {
          signal: abortCont.signal,
          headers: {
              "Authorization": "Bearer " + getToken()
          }
      }).then(res => {
        if(res.data === null || res.data === "") {
          return
        }
        const data = res.data
        
        let categoryLabels = [...new Set(data.map(moneyMovement => moneyMovement.cause))]
        
        let overallMoney = 0
        data.forEach((moneyMovement) => {
          if(moneyMovement.direction == "IN") {
            overallMoney += moneyMovement.value
          } else {
            overallMoney -= moneyMovement.value
          }
        })
        setMoney(overallMoney)
        for(let i = 30; i >= 0; i--) {
          let date = new Date(new Date().getTime() - (i * 86400000))
          let inValue = sum(filter(data.filter(moneyMovement => sameDay(new Date(moneyMovement.created), date)), "direction", "IN"), "value")
          let outValue = sum(filter(data.filter(moneyMovement => sameDay(new Date(moneyMovement.created), date)), "direction", "OUT"), "value")
          overallMoney -= inValue
          overallMoney += outValue
        }

        let newData = []
        let newMoneyMovementLabels = []
        let overallMoneyData = []
        for(let i = 30; i >= 0; i--) {
          let date = new Date(new Date().getTime() - (i * 86400000))
          newMoneyMovementLabels.push(date.toLocaleDateString("de-DE", { month: 'numeric', day: 'numeric'}))
          let inValue = sum(filter(data.filter(moneyMovement => sameDay(new Date(moneyMovement.created), date)), "direction", "IN"), "value")
          let outValue = sum(filter(data.filter(moneyMovement => sameDay(new Date(moneyMovement.created), date)), "direction", "OUT"), "value")
          overallMoney += inValue
          overallMoney -= outValue
          overallMoneyData.push(overallMoney)
        }
        categoryLabels.forEach((categoryLabel) => {
          let currentCaseData = []
          for(let i = 30; i >= 0; i--) {
            let date = new Date(new Date().getTime() - (i * 86400000))
            let inValue = sum(filter(filter(data.filter(moneyMovement => sameDay(new Date(moneyMovement.created), date)), "direction", "IN"), "cause", categoryLabel), "value")
            let outValue = sum(filter(filter(data.filter(moneyMovement => sameDay(new Date(moneyMovement.created), date)), "direction", "OUT"), "cause", categoryLabel), "value")
            let result = inValue - outValue
            currentCaseData.push(result)
          }
          newData.push({
            label: categoryLabel,
            data: currentCaseData,
            backgroundColor: [getRandomColor()],
          })
        })
        newData.push({
          label: "Geld im System",
          data: overallMoneyData,
          backgroundColor: [getRandomColor()],
          type: 'line',
        })
        setMoneyMovementLabels(newMoneyMovementLabels)
        setMoneyMovements(newData)

        let inCategoryData = []
        let outCategoryData = []
        let categoryColors = []
        categoryLabels.forEach((category) => {
          inCategoryData.push(sum(filter(filter(data, "direction", "IN"), "cause", category), "value"))
          outCategoryData.push(sum(filter(filter(data, "direction", "OUT"), "cause", category), "value"))
          categoryColors.push(getRandomColor())
        })
        setIncomingMoneyCauseChart(<Pie data={{
          labels: categoryLabels,
          datasets: [{
            data: inCategoryData,
            backgroundColor: categoryColors,
          }]
        }}/>)
        setOutgoingMoneyCauseChart(<Pie data={{
          labels: categoryLabels,
          datasets: [{
            data: outCategoryData,
            backgroundColor: categoryColors,
          }]
        }}/>)

      }).catch(err => {
        if(err.message === "canceled") {
          return
        }
        toast.error("Geldbewegungen konnten nicht geladen werden.")
      })

      return () => abortCont.abort()
    }, [])
  }

  return (
    <div className="dashboard">
      <div className="row">
        <div className="col-lg-3 col-6">
          <div className={"small-box bg-" + (openTickets.length === 0  ? "success" : openTickets.length > 10 ? "danger" : "warning")}>
            <div className="inner">
              <h3>{openTickets.length}</h3>
              <p>Offene Tickets</p>
            </div>
            <div className="icon">
            <i className="nav-icon fa-solid fa-clipboard-list-check"></i>
            </div>
            <Link to="/ticket" className="small-box-footer">Tickets anzeigen <i className="fas fa-arrow-circle-right"></i></Link>
          </div>
        </div>
        
        <div className="col-lg-3 col-6">
          <div className={"small-box bg-success"}>
            <div className="inner">
              <h3>{time}</h3>
              <p>Durchschnittliche Spielzeit</p>
            </div>
            <div className="icon">
            <i className="nav-icon fa-solid fa-timer"></i>
            </div>
            <Link to="/onlinetime" className="small-box-footer">Spielzeit anzeigen <i className="fas fa-arrow-circle-right"></i></Link>
          </div>
        </div>
        
        <div className="col-lg-3 col-6">
          <div className={"small-box bg-success"}>
            <div className="inner">
              <h3>{money}</h3>
              <p>Gesamtes Geld</p>
            </div>
            <div className="icon">
            <i className="nav-icon fa-solid fa-dollar-sign"></i>
            </div>
            <Link to="/moneymovement" className="small-box-footer">Geldbewegungen anzeigen <i className="fas fa-arrow-circle-right"></i></Link>
          </div>
        </div>
        
        <div className="col-lg-3 col-6">
          <div className={"small-box bg-success"}>
            <div className="inner">
              <h3>{players}</h3>
              <p>Spieler</p>
            </div>
            <div className="icon">
            <i className="nav-icon fa-solid fa-gamepad-modern"></i>
            </div>
            <Link to="/participant" className="small-box-footer">Spieler anzeigen <i className="fas fa-arrow-circle-right"></i></Link>
          </div>
        </div>
        
        <div className="col-lg-3 col-6">
          <div className={"small-box bg-success"}>
            <div className="inner">
              <h3>{factions}</h3>
              <p>Gefolge</p>
            </div>
            <div className="icon">
            <i className="nav-icon fa-solid fa-campground"></i>
            </div>
            <Link to="/faction" className="small-box-footer">Gefolge anzeigen <i className="fas fa-arrow-circle-right"></i></Link>
          </div>
        </div>
        
        <div className="col-lg-3 col-6">
          <div className={"small-box bg-" + (playersAboutToDelete == 0  ? "success" : playersAboutToDelete.length > 10 ? "danger" : "warning")}>
            <div className="inner">
              <h3>{playersAboutToDelete}</h3>
              <p>Charakterlöschungen diese Woche</p>
            </div>
            <div className="icon">
            <i className="nav-icon fa-solid fa-trash"></i>
            </div>
            <Link to="/character" className="small-box-footer">Charaktere anzeigen <i className="fas fa-arrow-circle-right"></i></Link>
          </div>
        </div>
        
        <div className="col-lg-3 col-6">
          <div className={"small-box bg-success"}>
            <div className="inner">
              <h3>{averagePlayersPerFaction}</h3>
              <p>Durchschnittliche Spieleranzahl pro Gefolge</p>
            </div>
            <div className="icon">
            <i className="nav-icon fa-solid fa-gauge"></i>
            </div>
            <Link to="/participant" className="small-box-footer">Spieler anzeigen <i className="fas fa-arrow-circle-right"></i></Link>
          </div>
        </div>
        
        <div className="col-lg-3 col-6">
          <div className={"small-box bg-success"}>
            <div className="inner">
              <h3>{averageIpScore}</h3>
              <p>Durchschnittlicher IP Score</p>
            </div>
            <div className="icon">
            <i className="nav-icon fa-solid fa-map-location"></i>
            </div>
            <Link to="/address" className="small-box-footer">IP Adressen anzeigen <i className="fas fa-arrow-circle-right"></i></Link>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-6 col-12">
          <div className="card card-success">
            <div className="card-header">
              <h3 className="card-title">Neue Tickets</h3>
            </div>
            <div className="card-body">
              <Bar data={{
                labels: ['Neue Tickets'],
                datasets: newTickets
              }}/>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6 col-12">
          <div className="card card-success">
            <div className="card-header">
              <h3 className="card-title">Ticket Kategorien</h3>
            </div>
            <div className="card-body">
              {ticketCategoryChart}
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6 col-12">
          <div className="card card-success">
            <div className="card-header">
              <h3 className="card-title">Clients</h3>
            </div>
            <div className="card-body">
              {clientChart}
            </div>
          </div>
        </div>
        
        <div className="col-xl-6 col-12">
          <div className="card card-success">
            <div className="card-header">
              <h3 className="card-title">Neue Verwarnungen</h3>
            </div>
            <div className="card-body">
              <Bar data={{
                labels: ['Neue Verwarnungen'],
                datasets: newWarns
              }}/>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6 col-12">
          <div className="card card-success">
            <div className="card-header">
              <h3 className="card-title">Verwarnungstypen</h3>
            </div>
            <div className="card-body">
              {punishmentCategoryChart}
            </div>
          </div>
        </div>
        
        <div className="col-xl-6 col-12">
          <div className="card card-success">
            <div className="card-header">
              <h3 className="card-title">Neue Fraktionen</h3>
            </div>
            <div className="card-body">
              <Bar data={{
                labels: ['Neue Fraktionen'],
                datasets: newFactions
              }}/>
            </div>
          </div>
        </div>
        
        <div className="col-xl-6 col-12">
          <div className="card card-success">
            <div className="card-header">
              <h3 className="card-title">Neue Spieler</h3>
            </div>
            <div className="card-body">
              <Bar data={{
                labels: ['Neue Spieler'],
                datasets: newParticipants
              }}/>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6 col-12">
          <div className="card card-success">
            <div className="card-header">
              <h3 className="card-title">Gefolgeränge</h3>
            </div>
            <div className="card-body">
              {factionRankChart}
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6 col-12">
          <div className="card card-success">
            <div className="card-header">
              <h3 className="card-title">Spielerränge</h3>
            </div>
            <div className="card-body">
              {playerRankChart}
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6 col-12">
          <div className="card card-success">
            <div className="card-header">
              <h3 className="card-title">Geschlechtsverteilung</h3>
            </div>
            <div className="card-body">
              {characterGenderChart}
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6 col-12">
          <div className="card card-success">
            <div className="card-header">
              <h3 className="card-title">Altersstufen</h3>
            </div>
            <div className="card-body">
              {characterAgeChart}
            </div>
          </div>
        </div>

        <div className="col-xl-12 col-12">
          <div className="card card-success">
            <div className="card-header">
              <h3 className="card-title">Letzte Geldbewegungen</h3>
            </div>
            <div className="card-body">
              <Bar data={{
                labels: moneyMovementLabels,
                datasets: moneyMovements
              }} options={{
                scales: {
                  x: {
                    stacked: true
                  }, y: {
                    stacked: true
                  }
                }
              }}/>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6 col-12">
          <div className="card card-success">
            <div className="card-header">
              <h3 className="card-title">Eingehendes Geld</h3>
            </div>
            <div className="card-body">
              {incomingMoneyCauseChart}
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6 col-12">
          <div className="card card-success">
            <div className="card-header">
              <h3 className="card-title">Ausgehendes Geld</h3>
            </div>
            <div className="card-body">
              {outgoingMoneyCauseChart}
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
 
export default Dashboard