import axios from "axios"
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { isLoggedIn } from "../user/User"

const RequestPasswordReset = () => {
    const [email, setEmail] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    useEffect(() => {
        if (isLoggedIn()) {
            navigate("/")
        }
    })

    async function requestPasswordReset() {
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_URL + "/reset/" + email).then(_ => {
            toast.success("Du hast eine E-Mail erhalten, um dein neues Passwort zu setzen.")
            navigate("/login")
        }).catch(error => {
            console.error(error)
            setIsLoading(false)
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Ungültige Anfrage.")
                    return
                } else if(statusCode === 404) {
                    toast.error("Es existiert kein Benutzer mit dieser E-Mail-Adresse.")
                    return
                } else if(statusCode === 409) {
                    toast.error("Du hast bereits vor Kurzem einem Anfrage gesendet.")
                    return
                }
            }
            toast.error("Serverfehler")
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        requestPasswordReset(email, navigate)
    }

    return (
        <div className="login-page">
            <div className="login-box">
                <div className="card card-outline card-primary">
                    <div className="card-header text-center">
                        <h1>{process.env.REACT_APP_APP_NAME}</h1>
                    </div>
                    <div className="card-body">
                        <p className="login-box-msg">Gebe deine E-Mail-Adresse ein um dein Passwort zurückzusetzen.</p>
                        <form onSubmit={handleSubmit}>
                            <div className="input-group mb-3">
                                <input type="email" className="form-control" placeholder="E-Mail-Adresse" required value={email} onChange={e => setEmail(e.target.value)} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    {
                                        isLoading ? 
                                        <button type="submit" className="btn btn-primary btn-block" disabled>E-Mail wird gesendet...</button>
                                        :
                                        <button type="submit" className="btn btn-primary btn-block">Passwort zurücksetzen</button>
                                    }
                                </div>
                            </div>
                        </form>

                        <p className="mt-3 mb-1">
                            <Link to="/login">Anmelden</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
 
export default RequestPasswordReset