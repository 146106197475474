import useBreadcrumbs from 'use-react-router-breadcrumbs'

const Header = () => {
  const routes = [
    { path: '/', props: { value: 'Home' }},
    { path: '/user', props: { value: 'Benutzer' }},
    { path: '/user/create', props: { value: 'Benutzer erstellen' }},
    { path: '/user/*', props: { value: 'Benutzer bearbeiten' }},
    { path: '/ticket', props: { value: 'Tickets' }},
    { path: '/ticket/*', props: { value: 'Ticket bearbeiten' }},
    { path: '/punishment', props: { value: 'Verwarnungen' }},
    { path: '/points', props: { value: 'Punkte' }},
    { path: '/points/*', props: { value: 'Punkte von Spieler bearbeiten' }},
    { path: '/onlinetime', props: { value: 'Spielzeit' }},
    { path: '/client', props: { value: 'Clients' }},
    { path: '/address', props: { value: 'IP Adressen' }},
    { path: '/server', props: { value: 'Server' }},
    { path: '/server/create', props: { value: 'Server erstellen' }},
    { path: '/server/*', props: { value: 'Server bearbeiten' }},
    { path: '/server/console/*', props: { value: 'Server Konsole' }},
    { path: '/player', props: { value: 'Spielersuche' }},
    { path: '/player/*', props: { value: 'Spielersuche' }},
    { path: '/participant', props: { value: 'Spieler' }},
    { path: '/faction', props: { value: 'Gefolge' }},
    { path: '/moneymovement', props: { value: 'Geldbewegungen' }},
    { path: '/character', props: { value: 'Charaktere' }},
    { path: '/*', props: { value: '404 Nicht gefunden' }},
  ]
  const breadcrumbs = useBreadcrumbs(routes)
  const active = breadcrumbs[breadcrumbs.length - 1].value

  return (
      <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h2>{active}</h2>
          </div>
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              {breadcrumbs.map((breadcrumb) => active === breadcrumb.value ? null : <li className="breadcrumb-item" key={breadcrumb.value}><a href={breadcrumb.key}>{breadcrumb.value}</a></li>)}
              <li className="breadcrumb-item active" key={active}>{active}</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  )
}
   
export default Header