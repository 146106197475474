import React, { useState } from "react"
import { useEffect } from "react"

const Permissions = ({ data, setData }) => {
    let permissions = Object.keys(data)
    var checkedList = []
    Object.keys(data).forEach((permission) =>  {
        if(data[permission]) {
            checkedList.push(permission)
        }
    })
    const [checked, setChecked] = useState(checkedList)
    const [checkboxes, setCheckboxes] = useState("")

    const handleCheck = (event) => {
        data[event.target.value] = event.target.checked
        setData(data)

        var updatedList = [...checked]
        if (event.target.checked) {
            updatedList = [...checked, event.target.value]
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1)
        }
        setChecked(updatedList)
    }

    function getPermissionLabel(permission) {
        switch (permission) {
            case "user":
                return "Benutzer"
            case "addresses":
                return "IP Adressen"
            default:
                return permission.charAt(0).toUpperCase() + permission.substring(1)
        }
    }

    function updateCheckboxes() {
        setCheckboxes(
            <>
                {permissions.map((permission) => (
                    <label htmlFor={permission} key={permission + "_label"} style={{margin: "10px"}}>
                        <input type="checkbox" id={permission} value={permission} key={permission} checked={checked.includes(permission)} onChange={handleCheck} />
                        {" " + getPermissionLabel(permission)}
                    </label>
                ))}
            </>
        )
    }

    useEffect(() => {
        updateCheckboxes()
    }, [data, checked])

    useEffect(() => {
        var checkedList = []
        Object.keys(data).forEach((permission) =>  {
            if(data[permission]) {
                checkedList.push(permission)
            }
        })
        setChecked(checkedList)
    }, [data])
  
    return (<>
        <div className="row">
            <h4>Berechtigungen</h4>
        </div>
        <div className="row">
            {checkboxes}
        </div>
    </>)
}

export default Permissions