import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Header from './Header'

import Dashboard from '../pages/Dashboard'
import UserList from '../pages/user/UserList'
import UserEdit from '../pages/user/UserEdit'
import UserCreate from '../pages/user/UserCreate'
import NotFound from '../pages/404'

import Footer from './Footer'
import { isLoggedIn } from "../user/User"
import TicketList from '../pages/ticket/TicketList'
import PunishmentList from '../pages/punishment/PunishmentList'
import AddressList from '../pages/address/AddressList'
import ClientList from '../pages/client/ClientList'
import ServerCreate from '../pages/server/ServerCreate'
import ServerList from '../pages/server/ServerList'
import ServerEdit from '../pages/server/ServerEdit'
import ServerConsole from '../pages/server/ServerConsole'
import PlayerSearch from '../pages/player/PlayerSearch'
import TicketEdit from '../pages/ticket/TicketEdit'
import PointsList from '../pages/points/PointsList'
import OnlinetimeList from '../pages/onlinetime/OnlinetimeList'
import ParticipantList from '../pages/participant/ParticipantList'
import FactionList from '../pages/faction/FactionList'
import MoneyMovementList from '../pages/moneymovement/MoneyMovementList'
import CharacterList from '../pages/character/CharacterList'
import PointsEdit from '../pages/points/PointsEdit'

function Wrapper() {
    const navigate = useNavigate()
    useEffect(() => {
        if (!isLoggedIn()) {
            navigate("/login")
        }
    })

    return (
        <div className="wrapper">
            <Navbar />

            <Sidebar />

            <div className="content-wrapper">
                <Header />

                <section className="content">
                    <div className="container-fluid">
                        <Routes>
                            <Route exact path="/" element={<Dashboard />} />
                            <Route exact path="/user" element={<UserList />} />
                            <Route exact path="/user/create" element={<UserCreate />} />
                            <Route path="/user/:uuid" element={<UserEdit />} />
                            <Route exact path="/ticket" element={<TicketList />} />
                            <Route exact path="/ticket/:id" element={<TicketEdit />} />
                            <Route exact path="/punishment" element={<PunishmentList />} />
                            <Route exact path="/points" element={<PointsList />} />
                            <Route path="/points/:uuid" element={<PointsEdit />} />
                            <Route exact path="/onlinetime" element={<OnlinetimeList />} />
                            <Route exact path="/client" element={<ClientList />} />
                            <Route exact path="/address" element={<AddressList />} />
                            <Route exact path="/server" element={<ServerList />} />
                            <Route exact path="/server/create" element={<ServerCreate />} />
                            <Route path="/server/:id" element={<ServerEdit />} />
                            <Route path="/server/console/:id" element={<ServerConsole />} />
                            <Route exact path="/player" element={<PlayerSearch />} />
                            <Route exact path="/player/:player" element={<PlayerSearch />} />
                            <Route exact path="/faction" element={<FactionList />} />
                            <Route exact path="/participant" element={<ParticipantList />} />
                            <Route exact path="/moneymovement" element={<MoneyMovementList />} />
                            <Route exact path="/character" element={<CharacterList />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </div>
                </section>
            </div>

            <Footer />
        </div>
    )
}

export default Wrapper
