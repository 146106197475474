const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="float-right d-none d-sm-block">
        <a href="https://github.com/Ottonien/MinecraftControlPanel-Frontend">GitHub</a>
      </div>
      <strong>Made with <i className="fa-solid fa-heart"></i> by <a href="https://marvinklar.de">Mr_Minecraft15</a>.</strong>
    </footer>
  )
}
 
export default Footer