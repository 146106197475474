import axios from "axios"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import BasicTableComponent, { NumberRangeColumnFilter, SelectColumnFilter } from "../../components/basic.table"
import { getToken } from "../../user/User"

const PunishmentList = ({filter}) => {
    const columns =  [{
        Header: "Id",
        accessor: "id",
        Filter: NumberRangeColumnFilter,
        filter: 'between',
    }, {
        Header: "Typ",
        accessor: "type",
        Filter: SelectColumnFilter,
        filter: 'includes',
    }, {
        Header: "Erstellt",
        accessor: (row, _) => {
            return new Date(row.created * 1000).toLocaleDateString("de-DE", { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }) + " Uhr"
        }
    }, {
        Header: "Ersteller",
        accessor: "creatorName"
    }, {
        Header: "Spieler",
        accessor: "punishedName"
    }, {
        Header: "Grund",
        accessor: "reason"
    }, {
        Header: "Zeit",
        accessor: "time"
    }]

    const [punishments, setPunishments] = useState([])
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/api/punishments" + (filter === undefined ? "" : "/" + filter), {
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        })
        .then(res => {
            if(res.data === null || res.data === "") {
              return
            }
            setPunishments(res.data)
        }).catch(err => {
            console.error(err)
            toast.error("Verwarnungen konnten nicht geladen werden.")
        })
    }, [filter])

    return (
        <div>
            <BasicTableComponent columns={columns} data={punishments} editUrlPart="punishment" />
        </div>
    )
}
   
export default PunishmentList