function FormButton({ label, id, required, value, setValue, icon, type, minLength, disabled, placeholder, size, labelSize, updateArgument }) {
  return (
    <div className="form-group row">
        {label === undefined ? "" :
          <label htmlFor={id} className={"col-form-label " + (labelSize === undefined ? "col-sm-2 col-md-2 col-lg-2" : labelSize)}>{label}</label>
        }
        <div className={"input-group " + (size === undefined ? "col-sm-6 col-md-6 col-lg-4" : size)}>
            {type === "textarea" ? 
              <textarea className="form-control" id={id} placeholder={placeholder === undefined ? label : placeholder} required={required} value={value}
                onChange={e => updateArgument === undefined ? setValue(e.target.value) : setValue(updateArgument, e.target.value)} minLength={minLength} disabled={disabled} />
              :
              <input type={type === undefined ? "text" : type} className="form-control" id={id} placeholder={placeholder === undefined ? label : placeholder} required={required} value={value}
                onChange={e => updateArgument === undefined ? setValue(e.target.value) : setValue(updateArgument, e.target.value)} minLength={minLength} disabled={disabled} />
            }
            <div className="input-group-append">
                <span className="input-group-text"><i className={icon}></i></span>
            </div>
        </div>
    </div>
  )
}

export default FormButton