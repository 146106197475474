import axios from "axios"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import BasicTableComponent, { NumberRangeColumnFilter } from "../../components/basic.table"
import { getToken } from "../../user/User"

const OnlinetimeList = ({filter}) => {
    const [onlinetimes, setOnlinetimes] = useState([])

    const columns =  [{
        Header: "Spieler",
        accessor: "playerName"
    }, {
        Header: "Spielzeit in Sekunden",
        accessor: "onlinetime",
        Filter: NumberRangeColumnFilter,
        filter: 'between',
    }, {
        Header: "Spielzeit",
        accessor: (row, _) => {
            var sec_num = row.onlinetime
            var days    = Math.floor(sec_num / 86400)
            sec_num     -= days * 86400
            var hours   = Math.floor(sec_num / 3600)
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60)
            var seconds = sec_num - (hours * 3600) - (minutes * 60)
        
            var dayString = days === 0 ? '' : days + ' Tage '
            var hoursString = hours === 0 ? '' : hours + ' Stunden '
            var minutesString = minutes === 0 ? '' : minutes + ' Minuten '
            return dayString + hoursString + minutesString + seconds + ' Sekunden'
        }
    }]

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/api/onlinetime" + (filter === undefined ? "" : "/" + filter), {
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        })
        .then(res => {
            if(res.data === null || res.data === "") {
              return
            }
            setOnlinetimes(res.data)
        }).catch(err => {
            console.error(err)
            toast.error("Spielzeiten konnten nicht geladen werden.")
        })
    }, [filter])

    return (
        <div>
            <BasicTableComponent columns={columns} data={onlinetimes} editUrlPart="onlinetime" />
        </div>
    )
}
   
export default OnlinetimeList