import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import FormButton from "../../components/FormButton"
import { getEMail, getToken, usePermissions } from "../../user/User"

const ServerCreate = () => {
    const navigate = useNavigate()
    
    const { permissions } = usePermissions(getEMail())
    useEffect(() => {
        if(permissions.server !== undefined && !permissions.server) {
            toast.error("Du hast keine Erlaubnis Server zu erstellen!")
            navigate("/")
        }
    }, [permissions])
    
    const [name, setName] = useState("")
    const [queryHostname, setQueryHostname] = useState("")
    const [queryPort, setQueryPort] = useState(25565)
    const [sshHostname, setSshHostname] = useState("")
    const [sshPort, setSshPort] = useState(22)
    const [sshUsername, setSshUsername] = useState("")
    const [sshPassword, setSshPassword] = useState("")
    const [sshKey, setSshKey] = useState("")
    const [screenName, setScreenName] = useState("")
    const [folder, setFolder] = useState("")
    const [stopCommand, setStopCommand] = useState("")

    async function createServer() {
        axios.post(process.env.REACT_APP_API_URL + "/api/servers", JSON.stringify({
            "name": name,
            "query_hostname": queryHostname,
            "query_port": parseInt(queryPort),
            "ssh_hostname": sshHostname,
            "ssh_port": parseInt(sshPort),
            "ssh_username": sshUsername,
            "ssh_password": sshPassword,
            "ssh_key": sshKey,
            "screen_name": screenName,
            "folder": folder,
            "stop_command": stopCommand
        }), {
        headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(res => {
            toast.success("Server " + name + " (Id " + res.data.id + ") erstellt.")
            navigate("/server/" + res.data.id)
        }).catch(error => {
            console.error(error)
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Ungültige Anfrage.")
                    return
                } else if(statusCode === 409) {
                    toast.error("Es existiert bereits ein Server mit diesem Namen.")
                    return
                } else if(statusCode === 406) {
                    toast.error("Der Server konnte nicht erstellt werden.")
                    return
                }
            }
            toast.error("Serverfehler")
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        createServer()
    }

    const handleBack = async e => {
        e.preventDefault()
        navigate("/server")
    }

    return (
        <>
            <button className="btn btn-primary back" onClick={handleBack}>Zurück zur Übersicht</button>

            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="card card-primary">
                    <div className="card-body">
                        <div className="col-12">
                            <FormButton label="Name" id="name" required="required" value={name} setValue={setName} icon="fa-solid fa-server" />
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="col-12">
                            <FormButton label="Server Query Adresse" id="queryHostname" required="required" value={queryHostname} setValue={setQueryHostname} icon="fa-solid fa-magnifying-glass" />
                            <FormButton label="Server Query Port" id="queryPort" required="required" value={queryPort} setValue={setQueryPort} icon="fa-solid fa-magnifying-glass" type="number" />
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="col-12">
                            <FormButton label="SSH Adresse" id="sshHostname" required="required" value={sshHostname} setValue={setSshHostname} icon="fa-solid fa-network-wired" />
                            <FormButton label="SSH Port" id="sshPort" required="required" value={sshPort} setValue={setSshPort} icon="fa-solid fa-network-wired" type="number" />
                            <FormButton label="SSH Benutzername" id="sshUsername" required="required" value={sshUsername} setValue={setSshUsername} icon="fa-solid fa-shield-keyhole" />
                            <FormButton label="SSH Passwort" id="sshPassword" value={sshPassword} setValue={setSshPassword} icon="fa-solid fa-key" type="password" />
                            <FormButton label="SSH Schlüssel" id="sshKey" value={sshKey} setValue={setSshKey} icon="fa-solid fa-file-lock" type="textarea" />
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="col-12">
                            <FormButton label="Screen Name" id="screenName" required="required" value={screenName} setValue={setScreenName} icon="fa-solid fa-window" />
                            <FormButton label="Server Ordner" id="folder" required="required" value={folder} setValue={setFolder} icon="fa-solid fa-folder" />
                            <FormButton label="Stop Befehl" id="stopCommand" required="required" value={stopCommand} setValue={setStopCommand} icon="fa-solid fa-power-off" />
                        </div>
                    </div>

                    <div className="card-footer">
                        <button type="submit" className="btn btn-success">Server erstellen</button>
                    </div>
                </div>
            </form>
        </>
    )
}
   
export default ServerCreate