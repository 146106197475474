import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { getEMail, getToken, usePermissions } from "../../user/User"

const ServerConsole = () => {
    let { id } = useParams()
    const navigate = useNavigate()
    const commandInputRef = useRef(null);
    
    const { permissions } = usePermissions(getEMail())
    useEffect(() => {
        if(permissions.server !== undefined && !permissions.server) {
            toast.error("Du hast keine Erlaubnis die Server Konsole zu öffnen!")
            navigate("/")
        }
    }, [permissions])
    
    id = parseInt(id)
    const [name, setName] = useState("Unbekannter Server")
    const [currentCommand, setCurrentCommand] = useState("")
    const [command, setCommand] = useState("")
    const [history, setHistory] = useState([])
    const [historyIndex, setHistoryIndex] = useState(-1)
    const [output, setOutput] = useState([])
    const [line, setLine] = useState(0)
    const [autoscroll, setAutoscroll] = useState(true)
    const [isLoadingName, setIsLoadingName] = useState(true)
    const [isLoadingLog, setIsLoadingLog] = useState(true)
    const [isExecutingCommand, setIsExecutingCommand] = useState(false)

    useEffect(() => {
        if(id !== null) {
            const abortCont = new AbortController()
            
            axios.get(process.env.REACT_APP_API_URL + "/api/servers/" + id, {
                signal: abortCont.signal,
                headers: {
                    "Authorization": "Bearer " + getToken()
                }
            }).then(res => {
                setIsLoadingName(false)
                if(res.data === null || res.data === "") {
                    return
                }
                setName(res.data.name)

                setInterval(async () => {
                    axios.post(process.env.REACT_APP_API_URL + "/api/servers/log", JSON.stringify({
                        "server_id": id,
                        "line": line
                    }),{
                        signal: abortCont.signal,
                        headers: {
                            "Authorization": "Bearer " + getToken()
                        }
                    }).then(res => {
                        if(res.data === null || res.data === "") {
                            return
                        }
                        let lineIndex = res.data.line_index
                        if(lineIndex === undefined) {
                            let lines = res.data.lines
                            if(lines.length > 0) {
                                setLine(line + lines.length)
                                setOutput(output => [...output, ...lines])
                                setIsLoadingLog(false)
                                if(autoscroll) {
                                    window["autoScroll"]()
                                }
                            }
                        } else {
                            setLine(lineIndex)
                            setOutput(res.data.lines)
                            setIsLoadingLog(false)
                            if(autoscroll) {
                                window["autoScroll"]()
                            }
                        }
                    }).catch(err => {
                        if(err.message === "canceled") {
                            return
                        }
                        setIsLoadingLog(false)
                    })
                }, 1000)
            }).catch(err => {
                if(err.message === "canceled") {
                    return
                }
                setIsLoadingName(false)
            })
        
            return () => abortCont.abort()
        }
    }, [id, line, autoscroll])

    const handleKeypress = e => {
        if (e.which === 13) {
            executeCommand()
        }
    }

    const handleHistory = e => {
        if(history.length === 0) {
            return
        }
        let currentHistoryIndex = historyIndex
        if(e.which === 38) {
            currentHistoryIndex += 1
        } else if(e.which === 40) {
            currentHistoryIndex -= 1
        } else {
            return
        }
        if(currentHistoryIndex < -1) {
            currentHistoryIndex = -1
        } else if(currentHistoryIndex > history.length - 1) {
            currentHistoryIndex = history.length - 1
        }
        setHistoryIndex(currentHistoryIndex)
        let newCommand
        let index = history.length - currentHistoryIndex - 1
        if(currentHistoryIndex > -1) {
            newCommand = history[index]
        }
        if(currentHistoryIndex === 0 && e.which === 38) {
            setCurrentCommand(command)
        }
        if(newCommand === undefined) {
            newCommand = currentCommand
        }
        setCommand(newCommand)
        var that = e.target
        setTimeout(function(){ that.selectionStart = that.selectionEnd = 10000 }, 0)
    }

    const executeCommand = async _ => {
        setIsExecutingCommand(true)
        axios.get(process.env.REACT_APP_API_URL + "/api/servers/" + id, {
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        })
        .then(res => {
            const screenName = res.data.screen_name
            
            axios.post(process.env.REACT_APP_API_URL + "/api/servers/execute", JSON.stringify({
                "server_id": id,
                "command": "screen -S " + screenName + " -p 0 -X stuff \"" + command + "^M\""
            }),{
                headers: {
                    "Authorization": "Bearer " + getToken()
                }
            }).then(_ => {
                setHistory(history => [...history, command])
                setHistoryIndex(-1)
                setCurrentCommand("")
                setCommand("")
                setIsExecutingCommand(false)
                commandInputRef.current.focus()
            }).catch(err => {
                console.error(err)
                toast.error("Serverfehler")
                setIsExecutingCommand(false)
                commandInputRef.current.focus()
            })
        }).catch(error => {
            console.error(error)
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Ungültige Anfrage.")
                    return
                } else if(statusCode === 404) {
                    toast.error("Es existiert kein Server mit der Id " + id + ".")
                    return
                }
            }
            toast.error("Serverfehler")
            setIsExecutingCommand(false)
        })
    }

    return (
        <div>
            <div className="console-container">
                <div className="console-header">
                    <h3>{isLoadingName ? "Lädt..." : name}</h3>
                </div>
                <div className="console-output" id="output">{isLoadingLog ? "Lädt..." : output.map(line => <>{line}<br /></>)}</div>
                <div className="console-input">
                    { isExecutingCommand ? 
                    <>
                        <input type="text" id="command" className="console-button" style={{width: "85%"}} value={command} disabled />
                        <button className="btn btn-success" disabled>Sendet...</button>
                    </>
                    :
                    <>
                        <input type="text" id="command" className="console-button" style={{width: "85%"}} onKeyPress={handleKeypress} onKeyDown={handleHistory} value={command} onChange={e => setCommand(e.target.value)} autoComplete="off" />
                        <button className="btn btn-success" onClick={executeCommand}>Senden</button>
                    </>
                    }
                    <input ref={commandInputRef} type="checkbox" id="autoScroll" className="console-checkbox" checked={autoscroll} onChange={e => setAutoscroll(e.target.checked)} />
                    <label htmlFor="autoScroll">Scrollen</label>
                </div>
            </div>
        </div>
    )
}

export default ServerConsole