import axios from "axios"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import BasicTableComponent, { NumberRangeColumnFilter, SelectColumnFilter } from "../../components/basic.table"
import { getToken } from "../../user/User"

const PointsList = ({filter}) => {
    const columns =  [{
        Header: "Punktetyp",
        accessor: "name",
        Filter: SelectColumnFilter,
        filter: 'includes',
    }, {
        Header: "Spielername",
        accessor: "player_name"
    }, {
        Header: "Punkte",
        accessor: "points",
        Filter: NumberRangeColumnFilter,
        filter: 'between',
    }, {
        Header: "Spieler UUID",
        accessor: "uuid",
    }, {
        Header: "Bearbeiten"
    }]

    const [points, setPoints] = useState([])
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/api/points" + (filter === undefined ? "" : "/" + filter), {
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        })
        .then(res => {
            if(res.data === null || res.data === "") {
              return
            }
            let data = res.data
            let dataList = []

            data.forEach(pointsEntry => {
                pointsEntry.stats.forEach(statsEntry => {
                    dataList.push({
                        "name": pointsEntry.name,
                        "player_name": statsEntry.player_name,
                        "points": statsEntry.points,
                        "uuid": statsEntry.player_uuid,
                    })
                })
            })

            setPoints(dataList)
        }).catch(err => {
            console.error(err)
            toast.error("Punkte konnten nicht geladen werden.")
        })
    }, [filter])

    return (
        <div>
            <BasicTableComponent columns={columns} data={points} editUrlPart="points" />
        </div>
    )
}
   
export default PointsList