import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { getEMail, useCurrentUser, usePermissions } from "../user/User"
import Logo from './../img/logo.png'

const Sidebar = () => {
  const [ sidebar, setSidebar ] = useState("")
  const { uuid, name } = useCurrentUser()
  const { permissions } = usePermissions(getEMail())

  useEffect(() => {
    setSidebar(<>
      <div className="user-panel mt-3 pb-3 mb-3 d-flex">
        <div className="image">
          { permissions.user !== undefined && permissions.user ?
            <Link to={"/user/" + uuid}>
              <img src={uuid === null ? null : "https://crafatar.com/avatars/" + uuid} className="img-circle elevation-2" alt="User" />
            </Link> :
            <a>
              <img src={uuid === null ? null : "https://crafatar.com/avatars/" + uuid} className="img-circle elevation-2" alt="User" />
            </a>
          }
        </div>
        <div className="info">
          { permissions.user !== undefined && permissions.user ?
            <Link to={"/user/" + uuid}>
              {name}
            </Link> :
            <a>
              {name}
            </a>
          }
        </div>
      </div>
  
      <nav className="mt-2">
        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          { permissions.user !== undefined && permissions.user ?
            <li className="nav-item">
              <Link to="/user" className="nav-link">
                <i className="nav-icon fa-solid fa-users"></i>
                <p>Benutzer</p>
              </Link>
            </li>
            : ""
          }
          <li className="nav-item">
            <Link to="/player" className="nav-link">
              <i className="nav-icon fa-solid fa-magnifying-glass"></i>
              <p>Spielersuche</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/faction" className="nav-link">
              <i className="nav-icon fa-solid fa-campground"></i>
              <p>Gefolge</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/participant" className="nav-link">
              <i className="nav-icon fa-solid  fa-gamepad-modern"></i>
              <p>Spieler</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/ticket" className="nav-link">
              <i className="nav-icon fa-solid fa-clipboard-list-check"></i>
              <p>Tickets</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/punishment" className="nav-link">
              <i className="nav-icon fa-solid fa-ban"></i>
              <p>Verwarnungen</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/points" className="nav-link">
              <i className="nav-icon fa-solid fa-hundred-points"></i>
              <p>Punkte</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/onlinetime" className="nav-link">
              <i className="nav-icon fa-solid fa-timer"></i>
              <p>Spielzeit</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/client" className="nav-link">
              <i className="nav-icon fa-solid fa-desktop"></i>
              <p>Clients</p>
            </Link>
          </li>
          { permissions.addresses !== undefined && permissions.addresses ?
            <li className="nav-item">
              <Link to="/address" className="nav-link">
                <i className="nav-icon fa-solid fa-location-crosshairs"></i>
                <p>IP Adressen</p>
              </Link>
            </li>
            : ""
          }
          { permissions.server !== undefined && permissions.server ?
            <li className="nav-item">
              <Link to="/server" className="nav-link">
                <i className="nav-icon fa-solid fa-server"></i>
                <p>Server</p>
              </Link>
            </li>
            : ""
          }
          <li className="nav-item">
            <Link to="/moneymovement" className="nav-link">
              <i className="nav-icon fa-solid fa-dollar-sign"></i>
              <p>Geldbewegungen</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/character" className="nav-link">
              <i className="nav-icon fa-solid fa-person"></i>
              <p>Charaktere</p>
            </Link>
          </li>

          <li className="nav-header">Dienste</li>
          <li className="nav-item">
            <a href="https://airtable.com/apps1ywRsPMD0V6DJ/tblDRxGSb2wEj09ms/viwIPEgm3XboOJmhH?blocks=hide" target="_blank" className="nav-link">
              <i className="nav-icon fa-solid fa-table"></i>
              <p>Airtable</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="https://airtable.com/appZL4xM4Giqx51F3/tblpiCtJGKH51gika/viwyWlVxmsGk8yCtF?blocks=hide" target="_blank" className="nav-link">
              <i className="nav-icon fa-solid fa-file-lines"></i>
              <p>Log Analyse</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="https://marvinklar.de/ottonien/questeditor/index.html" target="_blank" className="nav-link">
              <i className="nav-icon fa-solid fa-comment"></i>
              <p>Questeditor</p>
            </a>
          </li>
          { permissions.server !== undefined && permissions.server ?
            <>
              <li className="nav-item">
                <a href="https://app.statuscake.com/YourStatus2.php" target="_blank" className="nav-link">
                  <i className="nav-icon fa-solid fa-monitor-waveform"></i>
                  <p>StatusCake</p>
                </a>
              </li>
              <li className="nav-item">
                <a href="https://mysql.ottonien.com" target="_blank" className="nav-link">
                  <i className="nav-icon fa-brands fa-php"></i>
                  <p>PHPmyAdmin</p>
                </a>
              </li>
            </>
            : ""
          }
          <li className="nav-item">
            <a href={"https://github.com/" + process.env.REACT_APP_APP_NAME} target="_blank" className="nav-link">
              <i className="nav-icon fa-brands fa-github"></i>
              <p>GitHub</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="https://wiki.ottonien.com" target="_blank" className="nav-link">
              <i className="nav-icon fa-brands fa-wikipedia-w"></i>
              <p>Wiki</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="https://storage.ottonien.com" target="_blank" className="nav-link">
              <i className="nav-icon fa-solid fa-database"></i>
              <p>Nextcloud</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="https://grafana.ottonien.com" target="_blank" className="nav-link">
              <i className="nav-icon fa-solid fa-monitor-waveform"></i>
              <p>Grafana</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="http://stats.marvin-klar.de/" target="_blank" className="nav-link">
              <i className="nav-icon fa-solid fa-chart-column"></i>
              <p>Minetrack</p>
            </a>
          </li>
        </ul>
      </nav>
    </>)
  }, [permissions, name, uuid])

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <Link to="/" className="brand-link">
        <img src={Logo} alt="Logo" className="brand-image img-circle elevation-3" />
        <span className="brand-text font-weight-light">{process.env.REACT_APP_APP_NAME}</span>
      </Link>
  
      <div className="sidebar">
        {sidebar}
      </div>
    </aside>
  )
}
 
export default Sidebar
