import { useNavigate } from "react-router-dom"

const Navbar = () => {
  const navigate = useNavigate()

  const logout = () => {
    sessionStorage.clear()
    localStorage.clear()
    navigate("/login")
  }

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav">
        <li className="nav-item">
          <p className="nav-link" data-widget="pushmenu" role="button"><i className="fas fa-bars"></i></p>
        </li>
      </ul>

      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <p className="nav-link" data-widget="fullscreen" role="button">
            <i className="fas fa-expand-arrows-alt"></i>
          </p>
        </li>
        <li className="nav-item">
          <p className="nav-link" role="button" onClick={logout}>
            <i className="fa-solid fa-right-from-bracket"></i>
          </p>
        </li>
      </ul>
    </nav>
  )
}
 
export default Navbar